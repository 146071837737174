import React from 'react';
import styled from 'styled-components';
import { injectIntl, Link } from 'gatsby-plugin-intl';

import { Layout, SEO } from 'components';

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10vh auto 5vh auto;
  padding: 20px 50px;
  width: 300px;
  font-size: 26px;
  font-weight: bold;
  border-radius: 100px;
  border: 3px solid var(--dark-color);
  color: var(--dark-color);
  transition: box-shadow 342ms cubic-bezier(0.23, 1, 0.32, 1);

  :hover {
    box-shadow: 0px 9px 0 0 var(--accent-color);
  }

  :active {
    box-shadow: 0px 9px 0 0 var(--accent-color);
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Heading = styled.h1`
  display: block;
  margin: 80px auto 40px auto;
  font-size: 60px;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
  letter-spacing: -4px;
  -webkit-background-clip: text;

  @media (max-width: 768px) {
    font-size: 45px;
  }
`;

const Text = styled.p`
  max-width: 28em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  color: var(--dark-color-light);
  @media (max-width: 768px) {
    font-size: 19px;
  }
`;

const NotFound = ({ intl }) => {
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'not_found_title' })} />
      <Heading>{intl.formatMessage({ id: 'not_found_title' })}</Heading>
      <Text>{intl.formatMessage({ id: 'not_found_text' })}</Text>
      <Link to="/">
        <Button>{intl.formatMessage({ id: 'not_found_button' })}</Button>
      </Link>
    </Layout>
  );
};

export default injectIntl(NotFound);
